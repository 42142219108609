import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import { api } from "../../config";
import img13 from "../../assets/images/small/userimage.png";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { get_cookie } from "../../helpers/get_cookie";
import { beampusher } from "../../config";

const ProfileDropdown = () => {
  const User_id = JSON.parse(get_cookie("workauthUser")).user_id;

  // S3 Bucket URL
  const s3ImgUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (get_cookie("workauthUser")) {
      const obj = JSON.parse(get_cookie("workauthUser"));
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === undefined
            ? user.first_name
              ? user.first_name
              : obj.data.first_name
            : "Admin" || "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? obj.providerData[0].email
          : "Admin"
      );
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const [gbl, setGbl] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState("");

  const loadSettings = () => {
    const formData = {
      user_id: User_id,
    };
    axios
      .post("/profile-settings/data", formData)
      .then((response) => {
        console.log(response.data.user_profile);
        setGbl(response.data.user_profile);
        setEmployeeDetails(response.employeedetail);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(gbl.first_name);

  //*backend response*
  useEffect(() => {
    loadSettings();
  }, []);

  const handleLogout = () => {
    // localhost 
    // const beamsClient = new PusherPushNotifications.Client({
    //   instanceId: "d41554a5-9f54-45af-9c6d-1f481b36b0f2",
    // });

    // devbusiness
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: beampusher.INSTANCE_ID,
    });
    
    // Stop Pusher Beams client
    beamsClient
      .stop()
      .then(() => {
        // Perform additional logout actions if needed
        window.location.href = process.env.PUBLIC_URL + "/logout";
      })
      .catch((error) => {
        console.error("Error stopping beamsClient:", error);
        // Still proceed with logout even if stopping beamsClient fails
        window.location.href = process.env.PUBLIC_URL + "/logout";
      });
  };

  // console.log("Image Path:", api.WEB_URL);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-0 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {/* <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" /> */}
            <img
              className="rounded-circle header-profile-user"
              src={gbl && gbl.profile_pic ? s3ImgUrl + gbl.profile_pic : img13}
              alt="Header Avatar"
              style={{ width: "38px", height: "38px" }}
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {`${gbl.first_name} ${gbl.last_name}`}{" "}
                <i
                  className="ri-arrow-down-s-fill"
                  style={{ verticalAlign: "bottom" }}
                ></i>
              </span>
              {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {` ${gbl.first_name} ${gbl.last_name}`}!
          </h6>

          <DropdownItem href={"/user-profile"}>
            {/* <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>*/}
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile Settings</span>
          </DropdownItem>

          {/* <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem> */}
          <DropdownItem onClick={handleLogout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
